/* Centering header text over image */
.home-title{
    font-weight: 500;
    font-size: 170px;
    text-align: center;
    text-shadow: 3px 3px #2F4F4F;
    color: white;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

/* general overlay */
.overlay{
    display: flex;
    flex-wrap: wrap;
    background: transparent;
    transform: translate(-50%, -50%);
    position: relative;
}

/* general padding */

.pad-bottom{
    padding-bottom: 40px;
}

/* home paragraph header 1 */
.home-redHeader{
    color: #9e1b32;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 40px;
    padding-top: 40px;
    padding-bottom: 20px;
}
.body-text{
    font-weight: 400;
    font-size: 25px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: center;
}
.body-text:hover{
    text-decoration: none;
    color: black;
}
.body-text-left{
    font-weight: 400;
    font-size: 22px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: left;
    padding-left: 20px;
}
.body-text-left:hover{
    text-decoration: none;
    color: black;
}

/* general centering */
.centered{
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    top: 60%;
    left: 50%;
}
/* make sure images do not exceed parent size */
img{
    max-width: 100%;
}

/* centering and styling images in grid */
.grid-container{
    max-width: 80%;
}

.center-grid{
    justify-content: center;
}

.grid-side{
    /* margin-left: 25px; */
    margin-right: 25px;
}

.top{
    margin-top: 45px;
}

.bottom{
    margin-bottom: 45px;
}

.pop:hover{
    box-shadow: 0 0 32px #333;
}

/* size icons */
.icon{
    font-size: 100px;
}

.icon-row{
    justify-content: center;
    text-align: center;
}

/* button coloring on hover */
.bOne:hover{
    background-color: #1e6b52;
    border-radius: 50%;
}
    
.bTwo:hover{
    background-color: #0077c8;
    border-radius: 50%;
}

.bThree:hover{
    background-color: #ffcd10;
    border-radius: 50%;
}

/* style button text */
.buttonTxt{
    font-size: 30px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: center;
}
.buttonTxt:hover{
    text-decoration: none;
    color: black;
}
.buttonTxt-white{
    color: white;
    font-size: 35px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: center;
}
.buttonTxt-white:hover{
    text-decoration: none;
    color: white;
}

/* style button */
.round{
    border-radius: 50%;
    border: 0px;
}

/* header shadowing, text styling */

.shadow{
    box-shadow: 0 0 16px #333;
}

.Head{
    text-align:center;
    color:white;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 100px;
    height: 120px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 40px;
    margin-left: 40px;
}

/* page header coloring */

.team{
    background-color: #0077c8;
}
.abt{
    background-color: #9e1b32;
}

.results{
    background-color: #ffcd10;
}

/* contact bootstrap roster */
body{margin-top:20px;}
.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}
.pt-5 {
    padding-top: 3rem !important;
}
.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}
.border-0 {
    border: 0 !important;
}
.position-relative {
    position: relative !important;
}
.shadow-lg {
    box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
}
.card {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: .25rem;
}

.member-profile {
    top: -50px;
    left: 0;
}
.text-center {
    text-align: center !important;
}
.sizeF{
    padding-top: 35px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 18px;
}

.w-100 {
    width: 100% !important;
}
.position-absolute {
    position: absolute !important;
}

.member-profile img {
    width: 100px;
    height: 100px;
}
.rounded-circle {
    border-radius: 50% !important;
}
.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}
.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

/* padding about text */

.about-pad{
    padding-top: 40px;
    padding-bottom: 40px;
}

/* subheader text */

.subhead{
    font-size: 35px;
    font-weight: 700;
    font-family: Georgia, 'Times New Roman', Times, serif;
    padding-left: 20px;
}

.center-sub{
    text-align: center;
}

/* about image */
.aboutimg{
    max-width: 90%;
    max-height: 600px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 40px;
}

/* results page quotes */

.big-quote-text{
    padding-top: 40px;
    font-size: 25px;
    font-style: italic;
}

.big-quote-text:hover{
    text-decoration: none;
    color: black;
}

.big-quote-cred{
    color: black;
    font-size: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style: none;
    padding-left: 18px;
    padding-top: 0px;
}

.big-quote-cred:hover{
    text-decoration: none;
    color: black;
}

.quote-text{
    font-size: 20px;
    font-style: italic;
    color: white;
    padding-bottom: 0px;
}

.quote-text:hover{
    text-decoration: none;
    color: white;
}

.quote-cred{
    color: white;
    font-size: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    padding-left: 18px;
    padding-top: 0px;
}

.quote-cred:hover{
    text-decoration: none;
    color: white;
}

.quote2{
    background-color: rgb(94, 93, 93);
    opacity: 90%;
}

.quote2:hover{
    background-color: #333;
    opacity: 80%;
}

.quote3{
    background-color: rgb(94, 93, 93);
    opacity: 90%;
}

.quote3:hover{
    background-color: #333;
    opacity: 80%;
}

/* fancy block qutoes */


blockquote {
	position: relative;
	margin: 40px 0;
	padding: 1.6em 2.4em .7em calc(1.4em + var(--quote-image-width));
	font: italic 1.2rem var(--type-quote);
	background: var(--quote-bg) no-repeat left / var(--quote-image-width);
	border-radius: var(--border-rad);
	border: 2px solid white;
	box-shadow: 2px 2px 4px hsl(0 0% 0% / 20%);
	text-indent: 1.6em;
}

@media (min-width: 768px) {
	blockquote {
		margin: 40px 60px;
	}
}

blockquote::before {
	content: "";
	pointer-events: none;
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	border-radius: var(--border-rad);
	box-shadow:
		inset -2px -2px 1px hsl(0 0% 100%),
		inset 2px 2px 4px hsl(0 0% 0% / 20%);
}

blockquote::after {
	content: "❝";
	position: absolute;
	z-index: 1;
	left: 50%;
	top: -2px;
	transform: translate(-50%, -50%);
	width: 1.3em;
	height: 1.3em;
	background: white;
	box-shadow: 0 4px 5px -1px hsla(0 0% 0% / 20%);
	border-radius: 999px;
	display: grid;
	place-content: center;
	padding-top: .5em;
	color: var(--accent-color);
	font-size: 36px;
	font-style: normal;
	text-indent: 0;
}



cite {
	display: block;
	margin-top: 30px;
	text-indent: 0;
	text-align: center;
	font: bold .9rem var(--type-body);
	text-transform: uppercase;
	color: hsl(0 0% 20%);
}

@media (min-width: 768px) {
	cite {
		margin-left: calc(1rem - var(--quote-image-width));
	}
}

.cite-last-name {
	background: var(--accent-color);
	color: var(--quote-bg);
}

.align-left{
    text-align: center;
}

.text-shadow{
    text-shadow: 1.5px 1px #2F4F4F;
}

/* stories page button */

.results-btn{
    background-color: #9e1b32;
    color: #fff;
    border: #fff;
    border-radius: 8px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
    margin-top: 120px;
    margin-bottom: 120px;
}

.results-btn:hover{
    background-color: #fbd858;
    box-shadow: 0 3px 5px #d9ab05;
}

/* graph center */
.graphCenter{
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 77%;
    margin-bottom: 200px;
    margin-top: 80px;
}

.centerToGraph{
    padding-left: 200px;
}
.centerToGraphBL{
    padding-left: 285px;
}

.descrStyle{
    font-size: 19px;
    font-family: Georgia, Times, 'Times New Roman', serif;
    padding-top: 65px;
}
.descrStyleBL{
    font-size: 19px;
    font-family: Georgia, Times, 'Times New Roman', serif;
    padding-top: 45px;
}

.keyCenter{
    text-align: center;
}


/* video home */
.video-responsive {
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  /* socials buttons */
  .sm{
    width: 35px;
    margin-left: 20px;
    margin-right: 20px;
    border: none;
  }

  /* contact card height */
  .cardHeight{
    height: 100%;
  }

  /* navbar font size */
    .nav-link{
        font-size: 18px;
        margin-left: 20px;
    }