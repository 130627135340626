/* TOP NAVIGATION styling */
/* .topnav {
  padding-top: 0%;
  margin: 0px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  font-family: georgia;
  vertical-align: -webkit-baseline-middle;
} */
/* .logo {
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  padding-top: 10px;
  padding: 5px 15px 5px 15px;
} */
#root {
  width: 100%;
}

hr {
  height: 10px;
  border-style: solid;
  border-color: #9e1b32;
  border-width: 2px 0 0 0;
}

:global ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  float: left;
  padding: 15px;
}

a {
  display: block;
  padding: 10px;
  color: black;
}

/* homepage PHOTO BANNER */

.photoBanner {
  position: relative;
  font-size: 95px;
  font-family: Helvetica;
  color: white;
  text-align: center;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 30px;
  max-width: 99%;
  max-height: auto;
}

/*HEADER styling */

#headText {
  text-shadow: 3px 3px #2f4f4f;
  font-size: 170px;
}

.funHeader {
  text-align: center;
  font-family: century;
  font-size: 40px;
  padding: 0px;
  margin: 7px;
  color: #9e1b32;
}

.Head1 {
  text-align: center !important;
  color: white !important;
  font-size: 50px !important;
  font-family: helvetica !important;
  height: 120px !important;
  margin-bottom: 20px !important;
  margin-right: 40px !important;
  margin-left: 40px !important;
}

#abt {
  background-color: #9e1b32 !important;
}

#invHead {
  background-color: #1e6b52 !important;
}

#results {
  background-color: #ffcd10 !important;
}

#quotes {
  padding-left: 350px !important;
} 

.sub {
  font-size: 40px;
  font-weight: bold;
}

.spaceBottom {
  padding-bottom: 80px;
}

.spaceTop {
  padding-top: 80px;
}

.spaceTopX {
  padding-top: 200px;
}

.padLeft {
  padding-left: 265px;
}

.padRight {
  padding-right: 215px;
}

.nopad {
  padding: 0;
}

.marg {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.giveM {
  padding-left: 320px;
  padding-right: 320px;
}

.pad {
  margin: 100px !important;
}

.padSide {
  margin-left: 100px;
  margin-right: 100px;
}

.noBtm {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.noTop {
  padding-top: 0px;
  margin-top: 0px;
}

#team {
  background-color: #0077c8 !important;
  margin-left: 110px !important;
  margin-right: 110px !important;
}

#give {
  background-color: #303131 !important;
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.centered {
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.descr {
  padding-top: 10px;
  padding-left: 100px;
  padding-right: 100px;
  font-size: 27px;
  font-family: helvetica;
  text-align: center;
}

.descr2 {
  padding-top: 10px;
  padding-left: 50px;
  font-size: 27px;
  font-family: helvetica;
  text-align: center;
}

.desrcBox {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 27px;
  font-family: helvetica;
  text-align: center;
}

.bold {
  font-weight: bold;
}

.backOFF {
  background-color: #f2f3f4;
}

.shrinkText {
  font-size: 23px;
  text-align: left;
}

.noMarg {
  margin-bottom: 4px I !important;
}

.historyTitle {
  margin-top: 2px;
  text-align: center;
  font-size: 35px;
  font-family: helvetica;
  padding-top: 0px;
}

.history {
  text-decoration: underline;
  text-decoration-color: black;
}

#boxBorder {
  margin-top: 90px;
  text-align: center;
  border: 2px solid #050606;
  padding: 10px;
  outline: #1e6b52 solid 5px;
  outline-offset: 0px;
  width: 700px;
}

.bios {
  font-size: 20px;
  text-align: left;
  padding: 0px;
  outline-offset: 0px;
  margin-right: 325px;
  margin-left: 0%;
  font-weight: bold;
}

#boxblack {
  text-align: center;
  border: 2px solid #050606;
  padding: 10px;
  outline: #050606 solid 4px;
  outline-offset: 0px;
  width: 375px;
  margin-left: 50px;
  margin-right: 50px;
}

.full {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: grid;
}
.full .content {
  height: 100%;
  width: 100%;
  display: grid !important;
}
.full .content img {
  left: 50%;
  transform: translate3d(0, 0, 0);
  animation: zoomin 1s ease;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.byebye {
  opacity: 0;
}
.byebye:hover {
  transform: scale(0.2) !important;
}
.gallery {
  padding-left: 180px;
  padding-right: 180px;
  padding-top: 120px;
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: auto;
}

.partners {
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 30px;
}

.partners .content1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.partners .content2 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.partners .content3 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
}
.gallery img {
  display: grid;
  max-width: 100%;
  border-radius: 0px;
  box-shadow: 0 0 16px #333;
  transition: all 1.5s ease;
}

.sideSpace {
  padding-left: 50px;
  padding-right: 50px;
}

.shadow {
  box-shadow: 0 0 16px #333 !important;
}

.gallery img:hover {
  box-shadow: 0 0 32px #333;
}
.gallery .content {
  padding: 4px;
}
.gallery .gallery-item {
  transition: grid-row-start 300ms linear;
  transition: transform 300ms ease;
  transition: all 0.5s ease;
  cursor: pointer;
}
.gallery .gallery-item:hover {
  transform: scale(1.025);
}
@media (max-width: 600px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  }
}
@media (max-width: 400px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
@-moz-keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
@-webkit-keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
@-o-keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
@keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}

.circleButton {
  font-size: 130px;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  cursor: pointer;
  margin: 50px;
  transition: all 0.5s;
  border: 1px double #3d3935;
  box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.3), 0 7px 21px 0 rgba(0, 0, 0, 0.2);
}

#bOne {
  background-image: url(./images/address-card-solid.png);

  background-size: 60px;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
}

#bTwo {
  background-image: url(./images/hand-holding-heart-solid.png);
  background-size: 60px;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
}

#bThree {
  background-image: url(./images/bookmark-solid.png);
  background-size: 50px;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
}

#bOne:hover {
  background-color: #1e6b52;
}

#bTwo:hover {
  background-color: #0077c8;
}
#bThree:hover {
  background-color: #ffcd10;
}

.homeButtons {
  padding-top: 150px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.buttonText {
  font-family: helvetica;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 0px;
  font-size: 35px;
  /* padding-bottom: 80px; */
}

.large {
  color: white;
  font-size: 50px;
  margin-right: 100px;
  margin-left: 100px;
  border-radius: 5px;
  border-width: 4px;
}

.green {
  width: 270px;
  height: 80px;
  background-color: #1e6b52;
}

.blue {
  width: 350px;
  height: 100px;
  background-color: #303131;
}

.timelineCont {
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom: 80px;
}

.teamButton {
  /* font-family: serif; */
  margin-top: 80px;
  padding-bottom: 0px;
  /* text-align: center; */
  justify-content: center;
  /* font-size: 50px;
    font-style: bold; */
  width: 150px;
  height: 150px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 0;
  transition: all 0.5s;
  border: 3px solid black;
  border-width: 5px;
  box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.445), 0 7px 21px 0 rgba(0, 0, 0, 0.2);
  background-image: url(./images/team.png);
  background-size: 140px;
  background-repeat: no-repeat;
  color: black;
  /* text-decoration: underline;
    text-decoration-color: #1e6b52; */
}

.teamButton:hover {
  background-color: #9e1b32;
  height: 155px;
  width: 155px;
  background-size: 145px;
}

.meetTeam {
  margin-top: 0;
  padding-top: 0;
  text-align: center;
  font-size: 50px;
  padding-bottom: 80px;
}

.meetTeam:hover {
  font-size: 52px;
}

.backColor {
  background-color: #9e1b32;
}

#textWhite {
  color: white;
}

#open {
  padding-bottom: 80px;
}

#buttonIntro {
  text-align: center;
  padding-top: 90px;
  padding-bottom: 5px;
  margin-bottom: 0px;
}

.rosterPhoto {
  max-width: auto;
  max-height: 30px;
}

.roster {
  padding-left: 200px;
}

#shrinkImg {
  width: 300px;
  height: auto;
}

.rosterIMG {
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 40px;
  padding-top: 40px;
}

.rosterText {
  display: inline-block;
  margin-left: 70px;
  vertical-align: middle;
}

.genContact {
  display: inline-block;
  margin-left: 70px;
  vertical-align: middle;
  margin-top: 0px;
}

.textBack {
  color: white;
  background-color: #1e6b52;
}

.name {
  text-align: left;
  font-size: 30px;
  margin: 4px;
  /* text-decoration: underline;
    text-decoration-color: black; */
}

.role {
  padding-top: 10px;
  text-align: left;
  font-size: 20px;
  margin: 2px;
}

.email {
  margin-bottom: 1px;
  text-align: left;
}

.phone {
  margin-top: 1px;
  text-align: left;
}

.address {
  text-align: left;
}

.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.w3-padding {
  padding: 8px 16px !important;
}

.w3-padding-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-sm-4 {
  -webkit-box-flex: 0;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.col-md-8 {
  -webkit-box-flex: 0;
  flex: 0 0 33.333333334%;
  max-width: 33.333333334%;
}

.noPad {
  margin-right: 0px;
  padding-right: 2px;
}

.padRight {
  margin-right: 50px;
  margin-left: 0px;
  padding-left: 0px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.centerText {
  text-align: center;
  justify-content: center;
}

.sizeIMG {
  justify-content: center;
  width: 800px;
  height: auto;
}

.yellow {
  background-color: #ffcd10;
  width: 300px;
  height: auto;
}

.smallBtn {
  width: 300px;
  height: auto;
  justify-content: center;
}

.backPhoto {
  background-image: url(./images/writingBook.jpg);
  background-size: cover;
}

.imgCol {
  width: 300px;
  height: auto;
}

/* FOOTER styling--------------------------- */

.footer {
  display: inline-block;
  justify-content: center;
  padding-left: 180px;
  padding-right: 180px;
  padding-top: 120px;
  display: grid;
  background-color: #d3d3d3;
  /* clear: both; */
  /* height: 200px; */
  text-align: center;
  margin: 50px 0px 20px 0px;
}

.footerLogo {
  max-width: 100px;
  max-height: auto;
  object-fit: cover;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  margin: 10px;
  padding-top: 0px;
}

.copyright {
  padding-bottom: 0px;
  line-height: 1.5em;
  text-align: center;
  font-size: 14px;
}

.socialIcon {
  width: 29px;
  height: 29px;
  object-fit: cover;
}

.SocialMed {
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
}
