.green {
    width: 270px;
    height: 80px;
    background-color: #1e6b52;
  }
  
  .blue {
    width: 350px;
    height: 100px;
    background-color: #303131;
  }
  
  .timelineCont {
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 80px;
  }
  
  .teamButton {
    /* font-family: serif; */
    margin-top: 80px;
    padding-bottom: 0px;
    /* text-align: center; */
    justify-content: center;
    /* font-size: 50px;
      font-style: bold; */
    width: 150px;
    height: 150px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 0;
    transition: all 0.5s;
    border: 3px solid black;
    border-width: 5px;
    box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.445), 0 7px 21px 0 rgba(0, 0, 0, 0.2);
    background-image: url(./images/team.png);
    background-size: 140px;
    background-repeat: no-repeat;
    color: black;
    /* text-decoration: underline;
      text-decoration-color: #1e6b52; */
  }
  
  .teamButton:hover {
    background-color: #9e1b32;
    height: 155px;
    width: 155px;
    background-size: 145px;
  }
  
  .meetTeam {
    margin-top: 0;
    padding-top: 0;
    text-align: center;
    font-size: 50px;
    padding-bottom: 80px;
  }
  
  .meetTeam:hover {
    font-size: 52px;
  }
  
  .backColor {
    background-color: #9e1b32;
  }
  
  #textWhite {
    color: white;
  }
  
  #open {
    padding-bottom: 80px;
  }
  
  #buttonIntro {
    text-align: center;
    padding-top: 90px;
    padding-bottom: 5px;
    margin-bottom: 0px;
  }
  
  .rosterPhoto {
    max-width: auto;
    max-height: 30px;
  }
  
  .roster {
    padding-left: 200px;
  }
  
  #shrinkImg {
    width: 300px;
    height: auto;
  }
  
  .rosterIMG {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 40px;
    padding-top: 40px;
  }
  
  .rosterText {
    display: inline-block;
    margin-left: 70px;
    vertical-align: middle;
  }
  
  .genContact {
    display: inline-block;
    margin-left: 70px;
    vertical-align: middle;
    margin-top: 0px;
  }
  
  .textBack {
    color: white;
    background-color: #1e6b52;
  }
  
  .name {
    text-align: left;
    font-size: 30px;
    margin: 4px;
    /* text-decoration: underline;
      text-decoration-color: black; */
  }
  
  .role {
    padding-top: 10px;
    text-align: left;
    font-size: 20px;
    margin: 2px;
  }
  
  .email {
    margin-bottom: 1px;
    text-align: left;
  }
  
  .phone {
    margin-top: 1px;
    text-align: left;
  }
  
  .address {
    text-align: left;
  }
  
  .row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .w3-padding {
    padding: 8px 16px !important;
  }
  
  .w3-padding-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .col-sm-4 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    flex: 0 0 33.333333334%;
    max-width: 33.333333334%;
  }
  
  .noPad {
    margin-right: 0px;
    padding-right: 2px;
  }
  
  .padRight {
    margin-right: 50px;
    margin-left: 0px;
    padding-left: 0px;
  }
  
  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  
  .centerText {
    text-align: center;
    justify-content: center;
  }
  
  .sizeIMG {
    justify-content: center;
    width: 800px;
    height: auto;
  }
  
  .yellow {
    background-color: #ffcd10;
    width: 300px;
    height: auto;
  }
  
  .smallBtn {
    width: 300px;
    height: auto;
    justify-content: center;
  }
  
  .backPhoto {
    background-image: url(./images/writingBook.jpg);
    background-size: cover;
  }
  
  .imgCol {
    width: 300px;
    height: auto;
  }
  
  /* FOOTER styling--------------------------- */
  
  .footer {
    display: inline-block;
    justify-content: center;
    padding-left: 180px;
    padding-right: 180px;
    padding-top: 120px;
    display: grid;
    background-color: #d3d3d3;
    /* clear: both; */
    /* height: 200px; */
    text-align: center;
    margin: 50px 0px 20px 0px;
  }
  
  .footerLogo {
    max-width: 100px;
    max-height: auto;
    object-fit: cover;
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    margin: 10px;
    padding-top: 0px;
  }
  
  .copyright {
    padding-bottom: 0px;
    line-height: 1.5em;
    text-align: center;
    font-size: 14px;
  }
  
  .socialIcon {
    width: 29px;
    height: 29px;
    object-fit: cover;
  }
  
  .SocialMed {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
  }
  